import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";

import "./assets/css/font-awesome/css/fontawesome-all.min5f15.css";
import "element-plus/lib/theme-chalk/index.css";

createApp(App)
.use(store)
.use(ElementPlus)
.use(router)
.mount("#app");
