import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  // {path:"/",redi}
  {
    path: "/",
    name: "Index",
    redirect: "/home",
    component: function () {
      return import("../views/index.vue");
    },
    children: [
      {
        path: "/home",
        name: "Home",
        component: function () {
          return import("../views/Home.vue");
        },
      },
      {
        path: "/address",
        name: "Address",
        component: function () {
          return import("../views/address.vue");
        },
      },
      {
        path: "/block",
        name: "Block",
        component: function () {
          // bulock 详情页
          return import("../views/block.vue");
        },
      },
      {
        path: "/blocktxs",
        name: "BlockTxs",
        component: function () {
          // bulock 的交易列表
          return import("../views/blockTxs.vue");
        },
      },
      {
        path: "/accounts",
        name: "Accounts",
        component: function () {
          return import("../views/accounts.vue");
        },
      },
      {
        path: "/txDetail",
        name: "TxDetail",
        component: function () {
          return import("../views/txDetail.vue");
        },
      },
      {
        path: "/txTokenDetail",
        name: "TxTokenDetail",
        component: function () {
          return import("../views/txTokenDetail.vue");
        },
      },
      {
        path: "/txs",
        name: "Txs",
        component: function () {
          return import("../views/txs.vue");
        },
      },
      {
        path: "/txsInternal",
        name: "TxsInternal",
        component: function () {
          return import("../views/txsInner.vue");
        },
      },
      {
        path: "/tokens",
        name: "Tokens",
        component: function () {
          return import("../views/tokens.vue");
        },
      },
      {
        path: "/txsToken",
        name: "TxsToken",
        component: function () {
          return import("../views/txsToken.vue");
        },
      },
      {
        path: "/tokenDetail",
        name: "TokenDetail",
        component: function () {
          return import("../views/tokenDetail.vue");
        },
      },
      {
        path: "/blockTokenTxs",
        name: "BlockTokenTxs",
        component: function () {
          return import("../views/blockTokenTxs.vue");
        },
      },
      {
        path: "/contract_verify",
        name: "ContractVerify",
        component: function () {
          return import("../views/contract_verify.vue");
        },
      },
      {
        path: "/contracts",
        name: "Contracts",
        component: function () {
          return import("../views/contracts.vue");
        },
      },
      {
        path: "/rpc",
        name: "RPC",
        component: function () {
          return import("../views/rpc.vue");
        },
      },
    ],
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ "../views/About.vue");
  //   },
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
